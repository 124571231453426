
<div
  @click="close()"
  data-notify="container"
  class="alert open alert-with-icon"
  role="alert"
  :class="[verticalAlign, horizontalAlign, alertType]"
  :style="customPosition"
  data-notify-position="top-center">
  <button
    type="button"
    aria-hidden="true"
    class="close"
    data-notify="dismiss"
    @click="close">×
  </button>
  <i data-notify="icon" class="material-icons">{{icon}}</i>
  <span data-notify="message" v-html="message"></span>
</div>
