
<component :is="baseComponent"
           :to="link.path ? link.path : '/'"
           :class="{active : isActive}"
           tag="li">
  <a v-if="isMenu"
     href="#"
     class="nav-link sidebar-menu-item"
     :aria-expanded="!collapsed"
     data-toggle="collapse"
     @click.prevent="collapseMenu">
    <md-icon>{{link.icon}}</md-icon>
    <p>
      {{link.name}}
      <b class="caret"></b>
    </p>

  </a>

  <collapse-transition>
    <div v-if="$slots.default || this.isMenu" v-show="!collapsed">
      <ul class="nav" >
        <slot></slot>
      </ul>
    </div>
  </collapse-transition>

  <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
    <component
      :to="link.path"
      @click.native="linkClick"
      :is="elementType(link, false)"
      :class="{active: link.active}"
      class="nav-link"
      :target="link.target"
      :href="link.path">
      <template v-if="addLink">
        <md-icon>{{link.icon}}</md-icon>
        <span class="sidebar-normal">{{link.name}}</span>
      </template>
      <template v-else>
        <md-icon>{{link.icon}}</md-icon>
        <p>{{link.name}}</p>
      </template>
    </component>
  </slot>
</component>
