
<div class="notifications">
  <transition-group name="list" mode="in-out">
    <notification v-for="(notification) in notifications"
                  :key="notification.timestamp.getTime()"
                  :message="notification.message"
                  :icon="notification.icon"
                  :type="notification.type"
                  :timestamp="notification.timestamp"
                  :vertical-align="notification.verticalAlign"
                  :horizontal-align="notification.horizontalAlign"
                  @on-close="removeNotification">
    </notification>
  </transition-group>

</div>
